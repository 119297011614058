/**
 * Base Radio class used on most pages with the outline-type buttons.
 * Image radios (eg Container and Destination pyramid use the material-ui Radio).
 */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TouchRadio from './TouchRadio';

const green = theme => ({
  primary: {
    light: '#cafbcc',
    main: '#2e7d32',
    dark: '#005005',
    contrastText: '#fff',
  },
  secondary: {
    ...theme.palette.secondary,
  },
});

const buttonStyles = (theme, variant = 'default', scale = 1) => {
  const opts = variant === 'green' ? green(theme) : theme.palette;
  const highlightColor = variant === 'green' ? opts.primary.main : opts.secondary.main;
  return ({
    icon: {
      borderRadius: 12,
      borderWidth: 8,
      borderColor: highlightColor,
      borderStyle: 'solid',
      width: '300px',
      height: '110px',
      [theme.breakpoints.up('sm')]: {
        width: '260px',
        height: '160px',
      },
      [theme.breakpoints.up('md')]: {
        width: `${Math.floor(330 * scale)}px`,
        height: `${Math.floor(240 * scale)}px`,
      },
      [theme.breakpoints.up('lg')]: {
        width: '370px',
        height: '250px',
      },
      maxWidth: '100%',
      backgroundColor: '#ffffff',
      'input:hover ~ &': {
        backgroundColor: highlightColor,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        borderColor: 'rgba(206,217,224,.7)',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: highlightColor,
      'input:hover ~ &': {
        backgroundColor: highlightColor,
      },
    },
    label: {
      position: 'absolute',
      padding: 9,
      textAlign: 'center',
      width: '300px',
      [theme.breakpoints.up('sm')]: {
        padding: 11,
        width: '260px',
      },
      [theme.breakpoints.up('md')]: {
        padding: 12,
        width: `${Math.floor(330 * scale)}px`,
      },
      [theme.breakpoints.up('lg')]: {
        padding: 13,
        width: '370px',
      },
      marginLeft: 9,
      color: opts.primary.dark,
    },
  });
};

const useStyles = (variant, scale) => makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  ...buttonStyles(theme, variant, scale),
  title: {
    textTransform: 'uppercase',
  },
  labelRoot: {
    margin: 0,
  },
}));

const StyledRadio = ({ variant, scale, ...other }) => {
  const classes = useStyles(variant, scale)();

  return (
    <TouchRadio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...other}
    />
  );
};

StyledRadio.propTypes = {
  variant: PropTypes.oneOf(['default', 'green']),
  scale: PropTypes.number,
};

StyledRadio.defaultProps = {
  variant: 'default',
  scale: 1,
};

// **************

const MultilineLabel = ({ title, caption }) => {
  const classes = useStyles()();

  return (
    <div>
      <Typography variant="h3" className={classes.title}>{title}</Typography>
      <Typography variant="caption">{caption}</Typography>
    </div>
  );
};

MultilineLabel.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

MultilineLabel.defaultProps = {
  caption: '',
};

// *************

const RadioControl = ({
  title, caption, value, ...other
}) => {
  const classes = useStyles(null, other.scale)();
  return (
    <FormControlLabel
      classes={{ label: classes.label, root: classes.labelRoot }}
      value={value}
      control={<StyledRadio {...other} />}
      label={<MultilineLabel title={title} caption={caption} />}
    />
  );
};

RadioControl.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  value: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'green']),
};

RadioControl.defaultProps = {
  caption: '',
  variant: 'default',
};

export default RadioControl;
