import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, filter, get, lowerCase } from "lodash";

const useStyles = makeStyles((theme) => ({
  field: {
    fontSize: "2rem",
  },
}));

// nb: complex and campus are the same; complex is more recent
const CampusAutocomplete = ({ sector, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <Autocomplete
      id="complex"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      getOptionSelected={(option, value) => {
        if (isEmpty(value)) {
          return false;
        } else if (value.id === option.id) {
          return true;
        }
        return false;
      }}
      disableClearable
      classes={{ inputRoot: classes.field }}
      filterOptions={(options, state) => {
        const sectorId = get(sector, "id");
        if (!isEmpty(state.inputValue)) {
          return filter(
            options,
            (option) =>
              sectorId === option.sector_id &&
              lowerCase(option.name).indexOf(lowerCase(state.inputValue)) !== -1
          );
        }
        return filter(options, {
          sector_id: sectorId,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Complex"
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {props.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...props}
    />
  );
};

export default CampusAutocomplete;
