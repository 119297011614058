import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { map, reduce, filter, keys, find, isNil, isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  dialogContent: {
    minWidth: 400,
  },
  button: {
    fontSize: "1.5rem",
  },
}));

const ProfileDialog = ({
  open,
  profiles,
  selectedProfiles,
  handleClose,
  setProfiles,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["settings_page"]);

  const [selected, setSelected] = React.useState({});
  React.useEffect(() => {
    const initialState = reduce(
      profiles,
      (accumulator, profile) => {
        accumulator[profile.id] = !isNil(
          find(selectedProfiles, { id: profile.id })
        );
        return accumulator;
      },
      {}
    );
    setSelected(initialState);
  }, [profiles, selectedProfiles]);

  const handleChange = (event) => {
    setSelected({ ...selected, [event.target.value]: event.target.checked });
  };

  const submit = () => {
    const selectedProfileIds = filter(
      keys(selected),
      (key) => selected[key] === true
    );
    setProfiles(
      map(selectedProfileIds, (id) => find(profiles, { id: id * 1 }))
    );
    handleClose();
  };

  const handleCancel = () => {
    const initialState = reduce(
      profiles,
      (accumulator, profile) => {
        accumulator[profile.id] = !isNil(
          find(selectedProfiles, { id: profile.id })
        );
        return accumulator;
      },
      {}
    );
    setSelected(initialState);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="select-profiles-dialog-title"
    >
      <DialogTitle id="select-profiles-dialog-title">
        {t("select_profiles")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormGroup>
          {map(profiles, (profile) => (
            <FormControlLabel
              key={profile.id}
              control={
                <Checkbox
                  checked={selected[profile.id]}
                  onChange={handleChange}
                  name={profile.name}
                  value={profile.id}
                  color="primary"
                />
              }
              label={profile.name}
            />
          ))}
          {isEmpty(profiles) && <Typography>No profiles</Typography>}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          color="default"
          className={classes.button}
        >
          {t("cancel")}
        </Button>
        <Button onClick={submit} color="default" className={classes.button}>
          {t("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedProfiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClose: PropTypes.func.isRequired,
  setProfiles: PropTypes.func.isRequired,
};

export default ProfileDialog;
