import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WhyTossed from '../components/WhyTossed';
import { selectWhyTossed } from '~/WasteEntry/actions';

const mapStateToProps = ({ wasteEntry: { whyTossed }, userConfig: { submitDelay } }) => ({
  whyTossed,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectWhyTossed,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhyTossed);
