import map from 'lodash/map';
import { fork, all } from 'redux-saga/effects';
import settings from './Settings/sagas/index';
import saveWasteEntry from './WasteEntry/sagas';
import fetchAppInfo from './App/sagas/fetchAppInfo';

const childSagas = [
  ...settings,
  ...saveWasteEntry,
  ...fetchAppInfo,
];

export default function* mainSaga() {
  yield all(map(childSagas, childSaga => (fork(childSaga))));
}
