import { combineReducers } from 'redux';
import wasteEntry from './WasteEntry/reducers/wasteEntry';
import saveWasteEntry from './WasteEntry/reducers/saveWasteEntry';
import appMessage from './AppMessage/reducers';
import app from './App/reducers';
import appInfo from './App/reducers/fetchAppInfo';
import userConfig from './UserConfig/reducers';
import home from './Home/reducers';

import sectors from './Settings/reducers/fetchAllSectors';
import campuses from './Settings/reducers/fetchAllCampuses';
import kitchens from './Settings/reducers/fetchKitchens';
import profiles from './Settings/reducers/fetchProfiles';
import settings from './Settings/reducers/settings';
import duplicateTablets from './Settings/reducers/checkDuplicateTablet';

const rootReducer = combineReducers({
  wasteEntry,
  saveWasteEntry,
  appMessage,
  sectors,
  campuses,
  kitchens,
  profiles,
  settings,
  duplicateTablets,
  app,
  appInfo,
  userConfig,
  home,
});

export default rootReducer;
