import { setLastSync, setAppPin } from '../actions';

export const initialState = {
  lastSyncDate: null, // ms timestamp
  pin: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setLastSync.TYPE: {
      return {
        ...state,
        lastSyncDate: action.payload.lastSyncDate,
      };
    }
    case setAppPin.TYPE: {
      return {
        ...state,
        pin: action.payload.pin,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
