import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import es from './es.json';

export const LANGUAGES = ['en', 'es', 'fr'];

const i18n = (callback) => {
  i18next
    .use(initReactI18next)
    .init({
      lng: localStorage.getItem('lng') || 'en',
      resources: { en, es, fr },
      supportedLngs: LANGUAGES,
      fallbackLng: 'en',
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    }, callback);
};

export default i18n;
