import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

// selected from the dropdown
const selectProfile = createAction('SELECT_PROFILE');
selectProfile.TYPE = selectProfile.toString();

// updated by a fetch
const updateProfile = createAction('UPDATE_PROFILE');
updateProfile.TYPE = updateProfile.toString();

const selectKind = createAction('SELECT_KIND');
selectKind.TYPE = selectKind.toString();

const selectWhyTossed = createAction('SELECT_WHY_TOSSED');
selectWhyTossed.TYPE = selectWhyTossed.toString();

const selectDestination = createAction('SELECT_DESTINATION');
selectDestination.TYPE = selectDestination.toString();

const selectContainer = createAction('SELECT_CONTAINER');
selectContainer.TYPE = selectContainer.toString();

const selectQuantity = createAction('SELECT_QUANTITY');
selectQuantity.TYPE = selectQuantity.toString();

const selectType = createAction('SELECT_TYPE');
selectType.TYPE = selectType.toString();

// full reset
const resetWasteEntry = createAction('RESET_WASTE_ENTRY');
resetWasteEntry.TYPE = resetWasteEntry.toString();

// resets waste entry, but remembers selected profile
const newWasteEntry = createAction('NEW_WASTE_ENTRY');
newWasteEntry.TYPE = newWasteEntry.toString();

// resets quantity back to initial values
const resetQuantity = createAction('RESET_QUANTITY');
resetQuantity.TYPE = resetQuantity.toString();

const saveWasteEntry = createRoutine('SAVE_WASTE_ENTRY');


export {
  updateProfile,
  selectProfile,
  selectKind,
  selectWhyTossed,
  selectDestination,
  selectContainer,
  selectQuantity,
  selectType,
  saveWasteEntry,
  resetWasteEntry,
  newWasteEntry,
  resetQuantity,
};
