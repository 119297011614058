import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import App from '../components/App';
import { setLastSync, fetchAppInfo } from '../actions';
import { showAppMessage } from '~/AppMessage/actions';
import { setSubmitDelay } from '~/UserConfig/actions';

const mapStateToProps = ({ appInfo }) => ({
  appInfo,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setLastSync,
  showAppMessage,
  fetchAppInfo,
  setSubmitDelay,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
