import { createAction } from 'redux-actions';

const onClose = createAction('CLOSE_APP_MESSAGE');
onClose.TYPE = onClose.toString();

const showAppMessage = createAction('SHOW_APP_MESSAGE');
showAppMessage.TYPE = showAppMessage.toString();

export {
  onClose, showAppMessage,
};
