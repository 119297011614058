import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import MuiTheme from '~/Theme';
import BackButton from './BackButton';
import Header from '../containers/Header';
import AppMessage from '~/AppMessage/containers/AppMessage';

const useStyles = makeStyles(() => ({
  main: {
    position: 'relative',
    height: 'calc(100vh - 64px)', // header=64px
  },
}));

// change font sizes for smaller screens (but not the inputs)
// https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=BF360C&primary.color=263238&primary.text.color=ffffff&secondary.text.color=ffffff
const theme = responsiveFontSizes(createTheme(MuiTheme));

const Layout = ({ previousScreen, children, ...other }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header {...other} />
      <main className={classes.main}>
        {previousScreen && <BackButton previousScreen={previousScreen} />}
        {children}
      </main>
      <AppMessage />
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  children: <React.Fragment />,
  previousScreen: '',
};

Layout.propTypes = {
  previousScreen: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default Layout;
