export default {
  typography: {
    fontFamily: 'Lato Regular',
    h1: {
      fontFamily: 'Agenda-MediumUltraCondensed',
    },
    h2: {
      fontFamily: 'Agenda-MediumUltraCondensed',
      fontSize: '5.5rem',
      textTransform: 'uppercase',
      marginBottom: 20,
    },
    h3: {
      fontFamily: 'Agenda-MediumUltraCondensed',
      fontSize: '4rem',
    },
    h4: {
      fontFamily: 'Agenda-MediumUltraCondensed',
    },
    h5: {
      fontFamily: 'Agenda-MediumUltraCondensed',
    },
    h6: {
      fontFamily: 'Agenda-MediumUltraCondensed',
    },
    subtitle1: {
      fontFamily: 'Lato Medium',
      fontSize: '1.4rem',
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: 'Lato Medium',
      fontSize: '1.1rem',
      fontWeight: 600,
    },
    caption: {
      fontFamily: 'Lato Medium',
      fontSize: '1.2rem',
    },
  },
};
