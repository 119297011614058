import { saveWasteEntry } from '../actions';

export const initialState = {
  status: 'init',
  errors: null,
  message: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case saveWasteEntry.REQUEST: {
      return {
        ...state,
        errors: null,
        status: 'request',
      };
    }

    case saveWasteEntry.SUCCESS: {
      return {
        ...state,
        status: 'success',
        result: action.payload.result,
      };
    }

    case saveWasteEntry.FAILURE: {
      return {
        ...state,
        status: 'failure',
        errors: action.payload.errors,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
