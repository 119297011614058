import fetch from 'isomorphic-fetch';
import { put, call, takeLatest } from 'redux-saga/effects';
import { isNil } from 'lodash';
import logger from 'debug';
import { fetchAllSectors } from '../actions';
// import { resetWasteEntry } from '~/WasteEntry/actions';
import bamcoApiUrl from '~/api';

const doFetch = () => (
  fetch(bamcoApiUrl('sectors'))
    .then(response => response.json())
    .then(sectors => sectors)
    .catch((error) => {
      logger('app:error')('Error fetching sectors: ', error);
    })
);

function* fetchAllSectorsWorker() {
  yield put(fetchAllSectors.request());

  const sectors = yield call(doFetch);

  if (!isNil(sectors)) {
    yield put(fetchAllSectors.success({ sectors }));
  } else {
    // NB service worker will try cache first
    yield put(fetchAllSectors.failure({ error: 'Error requesting sectors' }));
  }
}

function* fetchAllSectorsWatcher() {
  yield takeLatest(fetchAllSectors.TRIGGER, fetchAllSectorsWorker);
}

export default [
  fetchAllSectorsWatcher,
];
