import { fetchProfiles } from '../actions';

export const initialState = {
  status: 'init',
  error: null,
  profiles: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchProfiles.REQUEST: {
      return {
        ...state,
        error: null,
        status: 'request',
      };
    }

    case fetchProfiles.SUCCESS: {
      return {
        ...state,
        status: 'success',
        profiles: action.payload.profiles,
      };
    }

    case fetchProfiles.FAILURE: {
      return {
        ...state,
        status: 'failure',
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
