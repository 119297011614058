import fetch from "isomorphic-fetch";
import { put, call, takeLatest } from "redux-saga/effects";
import { isNil, map, startCase, lowerCase } from "lodash";
import logger from "debug";
import { fetchAllCampuses } from "../actions";
import { resetWasteEntry } from "~/WasteEntry/actions";
import bamcoApiUrl from "~/api";

const doFetch = () =>
  fetch(bamcoApiUrl("campuses"))
    .then((response) => response.json())
    .then((campuses) => campuses)
    .catch((error) => {
      logger("app:error")("Error fetching campuses: ", error);
    });

function* fetchAllCampusesWorker() {
  yield put(fetchAllCampuses.request());

  const campuses = yield call(doFetch);

  if (!isNil(campuses)) {
    yield put(
      fetchAllCampuses.success({
        campuses: map(campuses, (campus) => ({
          ...campus,
          name: startCase(lowerCase(campus.name)),
        })),
      })
    );
    yield put(resetWasteEntry());
  } else {
    // NB service worker will try cache first
    yield put(fetchAllCampuses.failure({ error: "Error requesting campuses" }));
  }
}

function* fetchAllCampusesWatcher() {
  yield takeLatest(fetchAllCampuses.TRIGGER, fetchAllCampusesWorker);
}

export default [fetchAllCampusesWatcher];
