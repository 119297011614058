import {
  onClose, showAppMessage,
} from '../actions';

export const initialState = {
  open: false,
  message: '',
  variant: 'info',
  duration: 3000, // null for infinite
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case onClose.TYPE: {
      return {
        ...state,
        ...initialState,
      };
    }
    case showAppMessage.TYPE: {
      const { message, variant, duration } = action.payload;
      return {
        ...state,
        open: true,
        message,
        variant: variant || initialState.variant,
        duration: duration === undefined ? initialState.duration : duration,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
