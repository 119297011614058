import {
  setSubmitDelay,
  showCrumbTrail,
} from '../actions';

export const initialState = {
  showCrumbTrail: false,
  submitDelay: 250,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setSubmitDelay.TYPE: {
      return {
        ...state,
        submitDelay: action.payload.submitDelay,
      };
    }
    case showCrumbTrail.TYPE: {
      return {
        ...state,
        showCrumbTrail: action.payload.showCrumbTrail,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
