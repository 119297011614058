import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import AlertContent from './AlertContent';

// defaults are set in reducer
const AppMessage = ({
  appMessage: {
    open, message, variant, duration,
  },
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
    >
      <AlertContent
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  );
};

AppMessage.propTypes = {
  appMessage: PropTypes.shape({
    open: PropTypes.bool,
    message: PropTypes.string,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
    duration: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AppMessage;
