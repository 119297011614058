import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'absolute',
    top: 15,
    left: 0,
    padding: 5,
    [theme.breakpoints.up('sm')]: {
      top: 16,
    },
    zIndex: 5,
  },
  icon: {
    fontSize: 50,
    [theme.breakpoints.up('md')]: {
      fontSize: 80,
    },
  },
}));

const BackButton = ({ previousScreen }) => {
  const classes = useStyles();
  const goBack = () => navigate(previousScreen);

  return (
    <IconButton
      onClick={goBack}
      className={classes.button}
    >
      <ChevronLeftIcon className={classes.icon} color="primary" />
    </IconButton>
  );
};

BackButton.propTypes = {
  previousScreen: PropTypes.string.isRequired,
};

export default BackButton;

