import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import KindOfWaste from '../components/KindOfWaste';
import { selectKind } from '~/WasteEntry/actions';

const mapStateToProps = ({ wasteEntry: { kind, profile }, userConfig: { submitDelay } }) => ({
  kind,
  profile,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectKind,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KindOfWaste);
