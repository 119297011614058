import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { last } from 'lodash';
import { Link as RouterLink } from '@reach/router';
import Sidebar from '../containers/Sidebar';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  breadcrumbs: {
    flexGrow: 1,
  },
  language: {
    position: 'absolute',
    top: '-4px',
    right: '85px',
  },
  link: {
    color: theme.palette.common.white,
  },
  root: {
    color: theme.palette.common.white,
  },
}));

const Header = ({
  crumbs, lastSyncDate, showCrumbTrail,
}) => {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const toggleDrawer = open => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setSidebarOpen(open);
  };

  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          {showCrumbTrail && (
            <Breadcrumbs
              separator="›"
              aria-label="navigation"
              className={classes.breadcrumbs}
              classes={{ root: classes.root }}
            >
              {
                crumbs.length > 1 && crumbs.slice(0, -1).map(crumb => (
                  <Link
                    component={RouterLink}
                    to={crumb.to}
                    className={classes.link}
                    key={crumb.name}
                  >
                    {crumb.name}
                  </Link>
                ))
              }
              <Typography>{last(crumbs).name}</Typography>
            </Breadcrumbs>
          )}
        </Toolbar>
      </AppBar>
      <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} lastSyncDate={lastSyncDate} />
    </React.Fragment>
  );
};

Header.defaultProps = {
  lastSyncDate: 0,
};

Header.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  lastSyncDate: PropTypes.number,
  showCrumbTrail: PropTypes.bool.isRequired,
};

export default Header;
