import fetch from 'isomorphic-fetch';
import { put, call, takeLatest } from 'redux-saga/effects';
import { isNil, isEmpty, get } from 'lodash';
import logger from 'debug';
import { getI18n } from 'react-i18next';
import { setCampus, fetchKitchens } from '../actions';
import bamcoApiUrl from '~/api';
import { showAppMessage } from '~/AppMessage/actions';

const doFetch = url => (
  fetch(url)
    .then(response => response.json())
    .then(kitchens => kitchens)
    .catch((error) => {
      logger('app:error')('Error fetching kitchens: ', error);
    })
);

function* fetchKitchensWorker(action) {
  const campusId = get(action, "payload.campus.id");
  if (!campusId) {
    return;
  }
  const t = key => getI18n().t(key, { ns: 'settings_page' });

  yield put(fetchKitchens.request());

  const kitchens = yield call(doFetch, bamcoApiUrl('kitchens', { pathParams: { campusId } }));

  if (isEmpty(kitchens)) {
    yield put(showAppMessage({ variant: 'warning', message: t('error.no_kitchens') }));
    yield put(fetchKitchens.success({ kitchens: [] }));
  } else if (!isNil(kitchens)) {
    yield put(fetchKitchens.success({ kitchens }));
  } else {
    // NB service worker will try cache first
    yield put(fetchKitchens.failure({ error: 'Error requesting kitchens' }));
  }
}

function* setCampusWatcher() {
  yield takeLatest(setCampus.TYPE, fetchKitchensWorker);
}

function* fetchKitchensWatcher() {
  yield takeLatest(fetchKitchens.TRIGGER, fetchKitchensWorker);
}

export default [
  setCampusWatcher, fetchKitchensWatcher,
];
