import React from 'react';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import { touchHandler } from './TouchButton';

const TouchRadio = props => (
  // Radio seems to already have some sort of event pooling, so debounce not needed
  <Radio
    {...props}
    onTouchEnd={event => touchHandler(event, props.onClick)}
  />
);

TouchRadio.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TouchRadio;
