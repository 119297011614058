import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Quantity from '../components/Quantity';
import { selectQuantity } from '~/WasteEntry/actions';

const mapStateToProps = ({
  wasteEntry: { quantity, container },
  userConfig: { submitDelay },
}) => ({
  quantity,
  container,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectQuantity,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Quantity);
