import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';
import { getUnits } from '~/Summary/WeightCalculator';

const CssTextField = withStyles(theme => ({
  root: {
    '& .MuiInput-root': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '& label': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize: 28,
    },
    '& label.Mui-focused': {
      fontSize: 22,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.main,
      borderWidth: 3,
    },
    '& .MuiInput-underline:after': {
      borderWidth: 3,
    },
  },
}))(TextField);

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'visible',
    height: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  form: {
    overflow: 'hidden',
  },
  selectLabel: {
    fontWeight: 'bold',
    color: '#111',
    fontSize: 28,
  },
  submit: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      paddingRight: 30,
    },
  },
  continueIcon: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      right: -10,
    },
  },
  submitContainer: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: 20,
      right: 0,
    },
  },
  menuItem: {
    fontSize: 30,
  },
  formControl: {
    width: 120,
  },
  amountField: {
    width: 120,
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  cancelIcon: {
    padding: 0,
    marginTop: 11,
  },
}));

const selectStyles = makeStyles({
  root: {
    fontSize: '2rem',
    paddingTop: '10px',
    paddingBottom: '10px',

    '&:before': {
      borderBottomColor: '#111',
      borderWidth: 3,
    },
    '&:after': {
      borderWidth: 3,
    },
  },
}, { name: 'MuiInput' });

const unitStyles = makeStyles({
  formControl: {
    transform: 'scale(1) translateY(40px)',
  },
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.75)',
  },
}, { name: 'MuiInputLabel' });

const ExactQuantity = ({ quantity, selectQuantity, appSettings: { unitSystem } }) => {
  const { t } = useTranslation('exact_quantity_page');
  const { amount, units } = quantity;
  const classes = useStyles();

  // override some global styles
  selectStyles();
  unitStyles();

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required(t('profile_required')),
    units: Yup.string().required(t('units_required')),
  });

  return (
    <Layout crumbs={getCrumbs('home/kindOfWaste/whyTossed/destination/container/exactQuantity')} previousScreen="/container">
      <Box className={classes.container}>
        <Typography variant="h2" align="center">{t('title')}</Typography>
        <Formik
          initialValues={{
            amount: amount || '',
            units: units || '',
          }}
          onSubmit={(values) => {
            selectQuantity({
              type: 'exact',
              amount: values.amount,
              units: values.units,
            });
            navigate('/typeOfWaste');
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form noValidate className={classes.form}>
              <Grid container direction="column" spacing={10}>
                <Grid item>
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={10}>
                    <Grid item>
                      <CssTextField
                        id="amount"
                        label={t('amount')}
                        type="number"
                        autoFocus
                        value={values.amount}
                        error={errors.units && touched.units}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {values.amount && (
                                <IconButton
                                  classes={{ root: classes.cancelIcon }}
                                  aria-label="clear"
                                  onClick={() => setFieldValue('amount', '')}
                                >
                                  <CancelIcon />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                          classes: {
                            input: classes.amountField,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControl
                        className={classes.formControl}
                      >
                        {/* todo: the label is not hooked to the select */}
                        <InputLabel className={classes.selectLabel}>{t('units')}</InputLabel>
                        <Select
                          name="units"
                          value={values.units}
                          error={errors.units && touched.units}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                          }}
                        >
                          {getUnits(unitSystem).map(unit => (
                            <MenuItem
                              value={unit}
                              key={unit}
                              classes={{ root: classes.menuItem }}
                            >
                              {t(unit)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.submitContainer}>
                  <Box display="flex" flexDirection="row-reverse">
                    <Button type="submit" disabled={!values.amount || !values.units} className={classes.submit}>
                      {t('continue')}
                      <ChevronRightIcon className={classes.continueIcon} />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Layout>
  );
};

ExactQuantity.propTypes = {
  quantity: PropTypes.shape({
    amount: PropTypes.number,
    units: PropTypes.string,
  }).isRequired,
  selectQuantity: PropTypes.func.isRequired,
  appSettings: PropTypes.shape({
    unitSystem: PropTypes.oneOf(['imperial', 'metric']),
  }).isRequired,
};

export default ExactQuantity;
