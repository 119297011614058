import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';
import overrides from '~/Theme/overrides';

const useStyles = makeStyles(() => ({
  form: {
    height: '100%',
  },
  container: {
    height: '100%',
  },
  formControl: {
    width: '80vw',
  },
  row: {
    marginLeft: '10vw',
  },
  pinField: {
    fontSize: '2rem',
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  continue: {
    paddingRight: 30,
  },
  continueIcon: {
    position: 'absolute',
    right: -10,
  },
  spinner: {
    position: 'absolute',
  },
}));

const theme = createTheme({
  overrides: {
    ...overrides,
    // double the size of the initial select input label
    MuiInputLabel: {
      formControl: {
        transform: 'translate(0, 30px) scale(2)',
      },
    },
  },
});

/* eslint-disable-next-line */
const checkPin = pin => (pin.toString() === (function(){var M=Array.prototype.slice.call(arguments),J=M.shift();return M.reverse().map(function(U,v){return String.fromCharCode(U-J-7-v)}).join('')})(1,57,58)+(2).toString(36).toLowerCase()+(function(){var v=Array.prototype.slice.call(arguments),R=v.shift();return v.reverse().map(function(j,L){return String.fromCharCode(j-R-20-L)}).join('')})(49,117)+(1302).toString(36).toLowerCase());

const Pin = ({
  setAppPin,
  pin,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('pin_page');

  const validate = (values) => {
    const errors = {};

    if (!values.pin) {
      errors.pin = t('required');
    } else if (!checkPin(values.pin)) {
      errors.pin = t('invalid');
    }

    return errors;
  };

  return (
    <Layout crumbs={getCrumbs('home/pin')}>
      <Formik
        initialValues={{
          pin: pin || '',
        }}
        validate={validate}
        onSubmit={(values) => {
          if (checkPin(values.pin)) {
            setAppPin({ pin: values.pin });
            navigate('/settings');
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValidating,
        }) => (
          <ThemeProvider theme={theme}>
            <Form noValidate className={classes.form}>
              <Grid container direction="column" justifyContent="center" className={classes.container} spacing={1}>
                <Grid item className={classes.row}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="pin"
                      label={t('pin')}
                      value={values.pin}
                      type="number"
                      error={errors.pin && touched.pin}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        classes: {
                          input: classes.pinField,
                        },
                      }}
                    />
                    <FormHelperText>{errors.pin && touched.pin ? errors.pin : ''}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item className={classes.row}>
                  <FormControl className={classes.formControl}>
                    <Box display="flex" flexDirection="row-reverse">
                      <Button type="submit" disabled={isValidating} className={classes.continue}>
                        {t('submit')}
                        <ChevronRightIcon className={classes.continueIcon} />
                        {isValidating && (
                          <CircularProgress
                            className={classes.spinner}
                            size={20}
                          />
                        )}
                      </Button>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </ThemeProvider>
        )}
      </Formik>
    </Layout>
  );
};

Pin.defaultProps = {
  pin: null,
};

Pin.propTypes = {
  pin: PropTypes.number,
  setAppPin: PropTypes.func.isRequired,
};

export default Pin;
