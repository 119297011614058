const DESTINATIONS = [
  {
    color: '#254476',
    slug: 'FEED_HUNGRY_PEOPLE',
    submit: 'feed_hungry_people',
  },
  {
    color: '#347462',
    slug: 'FEED_ANIMALS',
    submit: 'feed_animals',
  },
  {
    color: '#D9AE58',
    slug: 'INDUSTRIAL_USES',
    submit: 'industrial_uses',
  },
  {
    color: '#EF8C31',
    slug: 'COMPOSTINGS',
    submit: 'composting',
  },
  {
    color: '#9E5339',
    slug: 'INCINERATION',
    submit: 'incineration_or_landfill',
  },
];

export default DESTINATIONS;
