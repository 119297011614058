/**
 * Global theme overrides.
 *
 * If you extend the theme for your component with its own overrides,
 * you must include these global ones too.
 */
import LatoRegular from 'Fonts/Lato-Regular.ttf';
import LatoMedium from 'Fonts/Lato-Medium.ttf';
import AgendaCondensed from 'Fonts/agenda_medium_ultra_condensed.ttf';

const latoRegular = {
  fontFamily: 'Lato Regular',
  fontStyle: 'normal',
  src: `
    local('Lato Regular'),
    url(${LatoRegular})
  `,
};

const latoMedium = {
  fontFamily: 'Lato Medium',
  fontStyle: 'normal',
  src: `
    local('Lato Medium'),
    url(${LatoMedium})
  `,
};

const agendaCondensed = {
  fontFamily: 'Agenda-MediumUltraCondensed',
  fontStyle: 'normal',
  src: `
    local('Agenda-MediumUltraCondensed'),
    url(${AgendaCondensed})
  `,
};

export default {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [agendaCondensed, latoRegular, latoMedium],
    },
  },
  MuiInputLabel: {
    formControl: {
      transform: 'translate(0, 30px) scale(2)',
    },
  },
  MuiButton: {
    root: {
      background: '#fff',
      borderRadius: 3,
      border: '2px solid #111',
      color: '#111',
      fontSize: 54,
      fontFamily: 'Agenda-MediumUltraCondensed',
      '& [class^=MuiSvgIcon-root]': {
        fontSize: 58,
      },
      '& [class^=MuiButton-label]': {
        lineHeight: 1,
      },
      '& [class*=MuiButton-text]': {
        padding: '10px 8px',
      },
      '&:hover, &:focus, &:active': {
        background: '#111 !important',
        color: '#fff',
      },
      '&:disabled': {
        borderColor: '#ddd !important',
      },
      '& [class^=MuiTouchRipple-root]': {
        display: 'none',
      },
    },
  },
};
