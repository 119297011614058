import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ExactQuantity from '../components/ExactQuantity';
import { selectQuantity } from '~/WasteEntry/actions';

const mapStateToProps = ({ wasteEntry: { quantity }, settings: { app: appSettings } }) => ({
  quantity,
  appSettings,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectQuantity,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExactQuantity);
