import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

const fetchAllSectors = createRoutine('FETCH_ALL_SECTORS');
const fetchAllCampuses = createRoutine('FETCH_ALL_CAMPUSES');
const fetchKitchens = createRoutine('FETCH_KITCHENS');
const fetchProfiles = createRoutine('FETCH_PROFILES');
const checkDuplicateTablet = createRoutine('CHECK_DUPLICATE_TABLET');

const setSector = createAction('SET_SECTOR');
setSector.TYPE = setSector.toString();

const setCampus = createAction('SET_CAMPUS');
setCampus.TYPE = setCampus.toString();

const setKitchen = createAction('SET_KITCHEN');
setKitchen.TYPE = setKitchen.toString();

const setProfiles = createAction('SET_PROFILES');
setProfiles.TYPE = setProfiles.toString();

const setUnitSystem = createAction('SET_UNIT_SYSTEM');
setUnitSystem.TYPE = setUnitSystem.toString();

const setTabletId = createAction('SET_TABLET_ID');
setTabletId.TYPE = setTabletId.toString();

export {
  fetchAllSectors,
  fetchAllCampuses,
  fetchKitchens,
  fetchProfiles,
  checkDuplicateTablet,
  setSector,
  setCampus,
  setKitchen,
  setProfiles,
  setUnitSystem,
  setTabletId,
};
