import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TouchRadio from '~/common/TouchRadio';

/**
 * Make an svg trapezoid
 * @param  {Number} width     full width (top edge)
 * @param  {Number} height    full height
 * @param  {String} fillColor hex bg color
 * @param  {Number} scale     how to fit in the viewport
 * @return {String}           the svg node in string form
 */
const svgTrapezoid = (width, height, fillColor, scale) => {
  const tail = height / Math.tan(60 * (Math.PI / 180)); // ~43.3
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg"
      height="${height * scale}"
      width="${width * scale}"
      fill="${fillColor}"
      viewBox="0 0 ${width} ${height}"
    >
      <path d="M0 0 L${width} 0 L${width - tail} ${height} L${tail} ${height} Z" />
    </svg>`;
  return svg;
};

const useStyles = (
  {
    width, height, fillColor, scale = 2, opacity = 1,
  } = {},
) => makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: `${width * scale}px`,
    height: `${height * scale}px`,
    backgroundImage: `url(data:image/svg+xml;base64,${btoa(svgTrapezoid(width, height, fillColor))})`,
    'input:hover ~ &': {
      backgroundImage: `url(data:image/svg+xml;base64,${btoa(svgTrapezoid(width, height, theme.palette.primary.main))})`,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      backgroundImage: `url(data:image/svg+xml;base64,${btoa(svgTrapezoid(width, height, 'rgba(206,217,224,.5)'))})`,
    },
  },
  label: {
    position: 'absolute',
    padding: 30, // related to height
    textAlign: 'center',
    width: `${width * scale}px`,
    color: theme.palette.primary.contrastText,
  },
  title: {
    textTransform: 'uppercase',
  },
  labelRoot: {
    margin: 0,
    alignItems: 'flex-start',
    opacity,
  },
  radioRoot: {
    padding: 0,
  },
}));

const StyledRadio = ({
  width, height, fillColor, ...other
}) => {
  const classes = useStyles({ width, height, fillColor })();

  return (
    <TouchRadio
      classes={{ root: classes.radioRoot }}
      className={classes.root}
      disableRipple
      color="default"
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={classes.icon} />}
      {...other}
    />
  );
};

StyledRadio.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const TrapezoidRadio = ({
  title, value, width, height, fillColor, opacity, ...other
}) => {
  const classes = useStyles({
    width, height, fillColor, opacity,
  })();
  return (
    <FormControlLabel
      classes={{ label: classes.label, root: classes.labelRoot }}
      value={value}
      control={<StyledRadio width={width} height={height} fillColor={fillColor} {...other} />}
      label={<Typography variant="caption" className={classes.title}>{title}</Typography>}
    />
  );
};

TrapezoidRadio.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fillColor: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
};

export default TrapezoidRadio;
