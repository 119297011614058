import {
  updateProfile,
  selectProfile,
  selectKind,
  selectWhyTossed,
  selectDestination,
  selectContainer,
  selectQuantity,
  selectType,
  resetWasteEntry,
  newWasteEntry,
  resetQuantity,
} from '../actions';

export const initialState = {
  profile: null,
  kind: null,
  whyTossed: null,
  destination: null,
  container: null,
  quantity: {
    type: null, // exact or percentage
    percentage: 0,
    count: 1,
    amount: 0,
    units: null,
  },
  type: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case selectProfile.TYPE: {
      return {
        ...state,
        profile: action.payload.profile,
      };
    }
    case updateProfile.TYPE: {
      return {
        ...state,
        profile: action.payload.profile,
      };
    }
    case selectKind.TYPE: {
      return {
        ...state,
        kind: action.payload.kind,
      };
    }
    case selectWhyTossed.TYPE: {
      return {
        ...state,
        whyTossed: action.payload.whyTossed,
      };
    }
    case selectDestination.TYPE: {
      return {
        ...state,
        destination: action.payload.destination,
      };
    }
    case selectContainer.TYPE: {
      return {
        ...state,
        container: action.payload.container,
      };
    }
    case selectQuantity.TYPE: {
      return {
        ...state,
        quantity: {
          ...state.quantity,
          ...action.payload,
        },
      };
    }
    case selectType.TYPE: {
      return {
        ...state,
        type: action.payload.type,
      };
    }
    case newWasteEntry.TYPE: {
      return {
        ...initialState,
        profile: state.profile,
      };
    }
    case resetQuantity.TYPE: {
      return {
        ...state,
        quantity: {
          ...initialState.quantity,
        },
      };
    }
    case resetWasteEntry.TYPE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
