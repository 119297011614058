import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Pin from '../components/Pin';
import { setAppPin } from '~/App/actions';

const mapStateToProps = ({
  app: { pin },
}) => ({
  pin,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setAppPin,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pin);
