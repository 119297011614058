import fetchAllSectors from './fetchAllSectors';
import fetchAllCampuses from './fetchAllCampuses';
import fetchKitchens from './fetchKitchens';
import fetchProfiles from './fetchProfiles';
import checkDuplicateTablet from './checkDuplicateTablet';

export default [
  ...fetchAllSectors,
  ...fetchAllCampuses,
  ...fetchKitchens,
  ...fetchProfiles,
  ...checkDuplicateTablet,
];
