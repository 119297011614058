import saveProfileId from '../actions';

export const initialState = {
  savedProfileId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case saveProfileId.TYPE: {
      return {
        ...state,
        savedProfileId: action.payload.profileId,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
