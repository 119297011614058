import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { some } from 'lodash';
import Settings from '../components/Settings';
import {
  fetchAllSectors, fetchAllCampuses, fetchKitchens, fetchProfiles,
  setSector, setCampus, setKitchen, setProfiles, setTabletId,
  checkDuplicateTablet, setUnitSystem,
} from '../actions';
import { resetWasteEntry } from '~/WasteEntry/actions';

const mapStateToProps = ({
  sectors: { sectors, status: sectorsStatus },
  campuses: { campuses, status: campusesStatus },
  kitchens: { kitchens, status: kitchensStatus },
  profiles: { profiles, status: profilesStatus },
  settings: { app },
  app: { pin },
  duplicateTablets,
}) => ({
  sectors,
  campuses,
  kitchens,
  profiles,
  loading: some([campusesStatus, kitchensStatus, profilesStatus], status => status === 'request'),
  appSettings: app, // todo: refactor to appSettings, remove nesting
  duplicateTablets,
  pin,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAllSectors,
  fetchAllCampuses,
  fetchKitchens,
  fetchProfiles,
  setSector,
  setCampus,
  setKitchen,
  setProfiles,
  setUnitSystem,
  setTabletId,
  checkDuplicateTablet,
  resetWasteEntry,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
