import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Home from '../components/Home';
import saveProfileId from '../actions';
import { selectProfile, resetWasteEntry } from '~/WasteEntry/actions';

const mapStateToProps = ({
  settings: { app },
  home: { savedProfileId },
}) => ({
  appSettings: app,
  savedProfileId,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectProfile,
  saveProfileId,
  resetWasteEntry,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
