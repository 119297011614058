import fetch from "isomorphic-fetch";
import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  isNil,
  map,
  omit,
  filter,
  isEmpty,
  difference,
  startCase,
  toLower,
} from "lodash";
import logger from "debug";
import { setKitchen, fetchProfiles } from "../actions";
import { selectProfile } from "~/WasteEntry/actions";
import bamcoApiUrl from "~/api";
import getAppSettings from "~/Settings/selectors";

const doFetch = (url) =>
  fetch(url)
    .then((response) => response.json())
    .then((profiles) =>
      map(profiles, (p) => {
        const containers = filter(
          p.container,
          (container) => !isEmpty(container.fill_images)
        );
        const diff = difference(p.container, containers);
        if (!isEmpty(diff)) {
          logger("app:error")(
            `Some containers missing images (skipping) in ${p.name}:`,
            diff
          );
        }
        return {
          id: p.id,
          name: startCase(toLower(p.name)),
          destinations: map(p.waste_destinations, (enabled, slug) => ({
            slug,
            enabled,
          })),
          kindsOfWaste: p.kinds_of_waste,
          containers: containers.map((container) => ({
            ...omit(container, "fill_images"),
            images: container.fill_images.map((image) => ({
              percentage: image.perc,
              url: `/assets/ot/${image.image}`,
            })),
          })),
        };
      })
    )
    .catch((error) => {
      logger("app:error")("Error fetching profiles: ", error);
    });

function* fetchProfilesWorker(action) {
  let { kitchen } = action.payload;
  if (isNil(kitchen)) {
    const appSettings = yield select(getAppSettings);
    kitchen = appSettings.kitchen;
  }
  yield put(fetchProfiles.request());

  const profiles = yield call(
    doFetch,
    bamcoApiUrl("profiles", { pathParams: { kitchenId: kitchen.id } })
  );

  if (!isNil(profiles)) {
    yield put(fetchProfiles.success({ profiles }));
  } else {
    // NB service worker will try cache first
    yield put(fetchProfiles.failure({ error: "Error requesting profiles" }));
  }
}

function* setKitchenWatcher() {
  yield takeLatest(setKitchen.TYPE, fetchProfilesWorker);
}

// when we select a profile on the home screen
function* selectProfileWatcher() {
  yield takeLatest(selectProfile.TYPE, fetchProfilesWorker);
}

function* fetchProfilesWatcher() {
  yield takeLatest(fetchProfiles.TRIGGER, fetchProfilesWorker);
}

export default [setKitchenWatcher, selectProfileWatcher, fetchProfilesWatcher];
