import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import getCrumbs from '~/Layout/crumbs';
import { Layout } from '~/Layout';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  title: {
    marginTop: 50,
    textTransform: 'uppercase',
  },
  link: {
    paddingLeft: 5,
    fontWeight: '700',
  },
}));

const Help = () => {
  const classes = useStyles();
  const { t } = useTranslation('help_page');

  return (
    <Layout crumbs={getCrumbs('home/help')}>
      <Box className={classes.container}>
        {t('content')}
        <a className={classes.link} href="mailto:wastenot@compass-usa.com">wastenot@compass-usa.com</a>
      </Box>
    </Layout>
  );
};

export default Help;
