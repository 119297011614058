import {
  setSector, setCampus, setKitchen, setProfiles, setTabletId, setUnitSystem,
} from '../actions';

export const initialState = {
  app: {
    sector: {},
    campus: {},
    kitchen: {},
    profiles: [],
    tabletId: '',
    unitSystem: 'imperial',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setSector.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          sector: action.payload.sector,

          // reset
          campus: {},
          kitchen: {},
          profiles: [],
        },
      };
    }

    case setCampus.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          campus: action.payload.campus,

          // reset
          kitchen: {},
          profiles: [],
        },
      };
    }
    case setKitchen.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          kitchen: action.payload.kitchen,

          // reset
          profiles: [],
        },
      };
    }
    case setProfiles.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          profiles: action.payload.profiles,
        },
      };
    }
    case setTabletId.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          tabletId: action.payload.tabletId,
        },
      };
    }
    case setUnitSystem.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          unitSystem: action.payload.unitSystem,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
