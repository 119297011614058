const TYPES_OF_WASTE = [
  {
    slug: 'DRY',
    coefficient: 0.47,
  },
  {
    slug: 'MIXED_WET_AND_DRY',
    coefficient: 0.89,
  },
  {
    slug: 'WET',
    coefficient: 1.31,
  },
  {
    slug: 'LIQUID',
    coefficient: 2.09,
  },
];

export default TYPES_OF_WASTE;
