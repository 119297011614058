/* global CONFIG */
import { stringify } from "query-string";
import { isNil, isFunction, template } from "lodash";

const { apiUrl, apiPaths, apiVersion } = CONFIG;

const baseUrl = `${apiUrl}/api/${apiVersion}`;

// relative path or template after channel
const paths = {
  modern: {
    sectors: "/sectors",
    campuses: "/complexes",
    kitchens: template("/complexes/<%= campusId %>/kitchens"),
    profiles: template("/kitchens/<%= kitchenId %>/profiles"),
    saveWasteEntry: "/wasteEntry",
    checkDuplicateTablet: template("/complexes/<%= complexId %>/checkTablet"), // tabletId in query
  },
  legacy: {
    campuses: "/campus",
    kitchens: template("/kitchen?campus=<%= campusId %>"),
    profiles: template("/profile?kitchen=<%= kitchenId %>"),
    saveWasteEntry: "/posttable",
    checkDuplicateTablet: "/tabletCheck",
  },
};

const bamcoApiUrl = (pathKey, opts = {}) => {
  const { pathParams, queryParams } = opts;
  let path = paths[apiPaths][pathKey];
  if (isNil(path)) {
    throw new Error(`No such pathKey: ${pathKey}`);
  }
  if (isFunction(path)) {
    path = path({ ...pathParams });
  }
  const query = queryParams ? `?${stringify({ ...queryParams })}` : "";
  return `${baseUrl}${path}${query}`;
};

export default bamcoApiUrl;
