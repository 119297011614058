import { fetchKitchens } from '../actions';

export const initialState = {
  status: 'init',
  error: null,
  kitchens: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchKitchens.REQUEST: {
      return {
        ...state,
        error: null,
        status: 'request',
      };
    }

    case fetchKitchens.SUCCESS: {
      return {
        ...state,
        status: 'success',
        kitchens: action.payload.kitchens,
      };
    }

    case fetchKitchens.FAILURE: {
      return {
        ...state,
        status: 'failure',
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
