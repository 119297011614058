import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

const setLastSync = createAction('SET_LAST_SYNC');
setLastSync.TYPE = setLastSync.toString();

const setAppPin = createAction('SET_APP_PIN');
setAppPin.TYPE = setAppPin.toString();

const fetchAppInfo = createRoutine('FETCH_APP_INFO');

export { setLastSync, setAppPin, fetchAppInfo };

