import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Container from '../components/Container';
import { selectContainer, resetQuantity } from '~/WasteEntry/actions';

const mapStateToProps = ({
  wasteEntry: { container, profile },
  userConfig: { submitDelay },
}) => ({
  container,
  profile,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectContainer,
  resetQuantity,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Container);
