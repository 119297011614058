import fetch from "isomorphic-fetch";
import { put, call, takeLatest, select } from "redux-saga/effects";
import logger from "debug";
import { checkDuplicateTablet } from "../actions";
import bamcoApiUrl from "~/api";
import getAppSettings from "~/Settings/selectors";

const doFetch = ({ campusId, tabletId }) => {
  return fetch(
    bamcoApiUrl("checkDuplicateTablet", {
      pathParams: { complexId: campusId },
      queryParams: { tabletId },
    })
  ).then((response) => response.json());
};

function* checkDuplicateTabletWorker() {
  const {
    campus: { id: campusId },
    tabletId,
  } = yield select(getAppSettings);
  if (campusId && tabletId) {
    yield put(checkDuplicateTablet.request());

    try {
      const response = yield call(doFetch, { campusId, tabletId });
      const { inUse } = response;
      yield put(checkDuplicateTablet.success({ inUse }));
    } catch (err) {
      let message;
      if (err.response) {
        // client received an error response (5xx, 4xx)
        logger("app:error")("Error checking tablet: ", err.response);
        message = err.response.data.reason || err.response.data.message;
      } else if (err.request) {
        // client never received a response, or request never left
        logger("app:error")("Error checking tablet: ", err.request);
        message = err.message;
      } else {
        // anything else
        logger("app:error")("Error checking tablet: ", err);
        message = err.message;
      }
      message = `Unable to check tablet: ${message}`;
      yield put(checkDuplicateTablet.failure({ error: message }));
    }
  }
}

function* checkDuplicateTabletWatcher() {
  yield takeLatest(checkDuplicateTablet.TRIGGER, checkDuplicateTabletWorker);
}

export default [checkDuplicateTabletWatcher];
