import logger from 'debug';
import { find, includes } from 'lodash';
import TYPES_OF_WASTE from '~/TypeOfWaste/typesOfWaste';
import convert from 'convert-units';

const IMPERIAL_UNITS = ['lbs', 'oz', 'gal', 'qt', 'pt'];
const METRIC_UNITS = ['kg', 'l', 'g', 'ml'];

export const getUnits = unitSystem => (
  unitSystem === 'metric' ? METRIC_UNITS : IMPERIAL_UNITS
);

class WeightCalculator {
  constructor(wasteEntry) {
    this.wasteEntry = wasteEntry;
    this.quantity = wasteEntry.quantity;
    this.converter = convert;
  }

  isExact = () => this.quantity.type === 'exact';

  isVolumetric = () => !includes(['oz', 'lbs', 'kg', 'g'], this.quantity.units);

  getWeightInLbs = () => {
    const { units, amount } = this.quantity;
    if (units === 'lbs') {
      return amount;
    }
    if (units === 'oz') {
      return amount / 16;
    }
    if (units === 'kg') {
      return amount * 2.20462;
    }
    if (units === 'g') {
      return (amount / 1000) * 2.20462;
    }
    logger('app:error')('Unsupported weight unit: ', units);
    throw new Error(`Unsupported weight unit: ${units}`);
  }

  getWeightInKg = () => {
    const { units, amount } = this.quantity;
    if (units === 'kg') {
      return amount;
    }
    if (units === 'g') {
      return amount / 1000;
    }
    logger('app:error')('Unsupported weight unit: ', units);
    throw new Error(`Unsupported weight unit: ${units}`);
  }

  getVolumeInQuarts = () => {
    const { units, amount } = this.quantity;
    if (units === 'qt') {
      return amount;
    }
    if (units === 'pt') {
      return amount / 2; // Refactor with convert-units
    }
    if (units === 'gal') {
      return amount * 4; // Refactor with convert-units
    }
    if (units === 'l') {
      return amount / 0.946353; // Refactor with convert-units
    }
    if (units === 'ml') {
      return (amount / 1000) / 0.946353; // Refactor with convert-units
    }
    logger('app:error')('Unsupported volume unit: ', units);
    throw new Error(`Unsupported volume unit: ${units}`);
  }

  /**
   * The coefficient for "types of waste" is an attempt to approximate 
   * the conversion from the items volume to an exact weight, specifically in lbs.
   * These items do not have an exact weight and the weight is guessed
   * based on what type of waste they are and how many quarts of it there is.
   */
  getWeightForVolume = (quarts, typeOfWaste) => (
    quarts * find(TYPES_OF_WASTE, { slug: typeOfWaste }).coefficient
  );

  // for non-exact (percentage) quantities
  /**
   * NOTE: Coefficient approximates the conversion from quarts to lbs
   */
  getWeightOfWasteByPercentage = () => {
    const { type, quantity, container } = this.wasteEntry;
    const { coefficient } = find(TYPES_OF_WASTE, { slug: type });
    return container.volume_qt
      * coefficient
      * quantity.count
      * (quantity.percentage / 100);
  };

  getWeight = (unitSystem = 'imperial') => {
    if (this.isExact()) {
      if (this.isVolumetric()) {
        let weight = this.getWeightForVolume(this.getVolumeInQuarts(), this.wasteEntry.type);
        if (unitSystem === 'metric') {
          weight = this.converter(weight).from('lb').to('kg');
        }
        return weight;
      }
      return unitSystem === 'imperial' ? this.getWeightInLbs() : this.getWeightInKg();
    }
    let weight = this.getWeightOfWasteByPercentage();
    if (unitSystem === 'metric') {
      weight = this.converter(weight).from('lb').to('kg');
    }
    return weight;
  }
}

export default WeightCalculator;
