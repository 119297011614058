import { useEffect, useState } from "react";

export function useWatchConnection() {
    // online/offline messaging
    const [online, setOnline] = useState(window.navigator.onLine);
    
    useEffect(() => {
        function onOffline() {
            setOnline(false);
        }
        function onOnline() {
            setOnline(true);
        }
        window.addEventListener("online", onOnline);
        window.addEventListener("offline", onOffline);

        return () => {
            window.removeEventListener("online", onOnline);
            window.removeEventListener("offline", onOffline);
        };
    }, [])

    return {
        online,
    }
    
}