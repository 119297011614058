/* global CONFIG */
import fetch from 'isomorphic-fetch';
import { put, call, takeLatest } from 'redux-saga/effects';
import logger from 'debug';
import { fetchAppInfo } from '../actions';

const doFetch = url => (
  fetch(url)
    .then(response => response.json())
);

function* fetchAppInfoWorker() {
  try {
    // todo: can consolidate now: "this is only implemented on our systems, so url resolution is different"
    yield put(fetchAppInfo.request());
    const appInfo = yield call(doFetch, CONFIG.appInfoUrl);
    yield put(fetchAppInfo.success({ appInfo }));
  } catch (error) {
    const message = `Error requesting app info: ${error}`;
    logger('app:error')(message);
    yield put(fetchAppInfo.failure({ error: message }));
  }
}

function* fetchAppInfoWatcher() {
  yield takeLatest(fetchAppInfo.TRIGGER, fetchAppInfoWorker);
}

export default [
  fetchAppInfoWatcher,
];
