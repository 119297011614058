import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// for new props, add a new version and migration
const migrations = {
  0: state => ({
    ...state,
    settings: {
      ...state.settings,
      app: {
        ...state.settings.app,
        unitSystem: 'imperial',
      },
    },
  }),
};

export default function configureStore({ reducer, mainSaga }) {
  const sagaMiddleware = createSagaMiddleware();

  // we can only support one type of storage at a time
  // see https://github.com/rt2zz/redux-persist/issues/897
  const localPersistConfig = {
    key: 'ontrack',
    version: 0,
    storage,
    stateReconciler: autoMergeLevel2,
    migrate: createMigrate(migrations, { debug: false }),

    // only store user selections for now
    whitelist: [
      'wasteEntry', 'settings', 'home',
      'kitchens', 'profiles', 'campuses',
      'app', 'userConfig', 'appMessage',
    ],
  };

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    persistReducer(localPersistConfig, reducer),
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );
  /* eslint-enable */
  const persistor = persistStore(store);
  sagaMiddleware.run(mainSaga);

  return { store, persistor };
}
