/**
 * This class normalizes some of the small swipe behaviour we are seeing.
 * On tablets, button touches are often actually button swipes, and we don't respond to them.
 * This invokes the button onClick at the end of a swipe if within bounds.
 */
import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { first, get, debounce } from 'lodash';

export const touchHandler = (event, callback) => {
  const element = event.target;
  const touchEnd = first(event.changedTouches);
  const touchX = get(touchEnd, 'clientX');
  const touchY = get(touchEnd, 'clientY');
  const rect = element.getBoundingClientRect();
  const isButtonTouched = (
    touchX >= rect.left && touchX <= rect.right
      && touchY >= rect.top && touchY <= rect.bottom
  );
  if (isButtonTouched) {
    callback(event);
  }
};

const TouchButton = ({ onClick, ...props }) => {
  // pause for 100ms, consolidate all invokes for 1s
  const debounced = debounce(onClick, 100, {
    leading: false,
    trailing: true,
    maxWait: 1000,
  });
  return (
    <Button
      {...props}
      onClick={debounced}
      onTouchEnd={event => touchHandler(event, debounced)}
    />
  );
};

TouchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default TouchButton;
