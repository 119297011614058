import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Destination from '../components/Destination';
import { selectDestination } from '~/WasteEntry/actions';

const mapStateToProps = ({
  wasteEntry: { destination, profile, kind },
  userConfig: { submitDelay },
}) => ({
  destination,
  kind,
  profile,
  submitDelay,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  selectDestination,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Destination);
