import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import RadioControl from '~/common/RadioControl';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  gridCenter: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      bottom: -100,
    },
    [theme.breakpoints.up('lg')]: {
      bottom: -120,
    },
  },
  gridFill: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

const WhyTossed = ({ whyTossed, selectWhyTossed, submitDelay }) => {
  const classes = useStyles();
  const { t } = useTranslation('why_tossed_page');

  const handleChange = (event) => {
    selectWhyTossed({ whyTossed: event.target.value });
    setTimeout(() => navigate('/destination'), submitDelay);
  };

  const gridItemClass = (reason, i) => {
    let itemClass = !reason ? classes.gridFill : '';
    if (i === 1) {
      itemClass = classes.gridCenter;
    }
    return itemClass;
  };

  const radio = reason => (
    <RadioControl
      title={t(`buttons.${reason}.title`)}
      caption={t(`buttons.${reason}.caption`)}
      onClick={handleChange}
      value={reason}
      name="whyTossed"
      checked={whyTossed === reason}
      inputProps={{ 'aria-label': t(`buttons.${reason}.title`) }}
      scale={0.9}
    />
  );

  const reasons = [
    'POOR_QUALITY',
    'OVERPRODUCTION',
    'WHOOPS',
    'FOOD_SAFETY',
    null, // allow for unique grid layout on some screen sizes (vertically centered second item)
    'ODDS_ENDS',
  ];

  return (
    <Layout crumbs={getCrumbs('home/kindOfWaste/whyTossed')} previousScreen="/kindOfWaste">
      <Box className={classes.container}>
        <Typography variant="h2" align="center">{t('title')}</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label={t('short_title')} name="whyTossed">
            <Grid container direction="row" align="center" justifyContent="center" spacing={1}>
              {reasons.map((reason, i) => {
                const key = `whyTossed-reason-${i}`;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={key}
                    className={gridItemClass(reason, i)}
                  >
                    {reason && radio(reason)}
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
    </Layout>
  );
};

WhyTossed.defaultProps = {
  whyTossed: '',
};

WhyTossed.propTypes = {
  whyTossed: PropTypes.string,
  selectWhyTossed: PropTypes.func.isRequired,
  submitDelay: PropTypes.number.isRequired,
};

export default WhyTossed;
