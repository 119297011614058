import { fetchAppInfo } from '../actions';

export const initialState = {
  status: 'init',
  error: null,
  appInfo: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchAppInfo.REQUEST: {
      return {
        ...state,
        error: null,
        status: 'request',
      };
    }

    case fetchAppInfo.SUCCESS: {
      return {
        ...state,
        status: 'success',
        appInfo: action.payload.appInfo,
      };
    }

    case fetchAppInfo.FAILURE: {
      return {
        ...state,
        status: 'failure',
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
