import logger from 'debug';

const fetchWaitingToSyncCount = (callback) => {
  const request = indexedDB.open('workbox-background-sync');
  request.onsuccess = (event) => {
    const db = event.target.result;
    if (db.objectStoreNames.contains('requests')) {
      const transaction = db.transaction('requests', 'readonly');
      const store = transaction.objectStore('requests');
      const countRequest = store.count(); // NB this is all requests in all queue names
      countRequest.onsuccess = ev => callback(ev.target.result);
    } else {
      callback(0);
    }
    db.close('workbox-background-sync');
  };
  request.onerror = () => {
    logger('app:error')('Error getting queue count: ', request.error);
  };
};

export default fetchWaitingToSyncCount;
