import { createAction } from 'redux-actions';

const setSubmitDelay = createAction('SET_SUBMIT_DELAY');
setSubmitDelay.TYPE = setSubmitDelay.toString();

const showCrumbTrail = createAction('SHOW_CRUMB_TRAIL');
showCrumbTrail.TYPE = showCrumbTrail.toString();

export {
  setSubmitDelay,
  showCrumbTrail,
};
