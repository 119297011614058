import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  InputAdornment,
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Box,
  Button,
  CircularProgress,
  Select,
  Dialog,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  get,
  find,
  isEmpty,
  isNil,
  sortBy,
  lowerCase,
  map,
  startCase,
  has,
  join,
  intersectionBy,
} from "lodash";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { Layout } from "~/Layout";
import getCrumbs from "~/Layout/crumbs";
import overrides from "~/Theme/overrides";
import { precacheAllImages } from "~/Settings/precache";
import { LANGUAGES } from "~/i18n";
import ProfileDialog from "./ProfileDialog";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CampusAutocomplete from "./CampusAutocomplete";
import { useWatchConnection } from "../hooks/useWatchConnection";

const useStyles = makeStyles((theme) => ({
  form: {
    height: "100%",
  },
  container: {
    minHeight: "100%",
    width: "100%",
  },
  formControl: {
    width: "80vw",
    marginBottom: theme.spacing(0.5),
  },
  row: {
    marginLeft: "10vw",
  },
  field: {
    fontSize: "2rem",
  },
  spinner: {
    position: "absolute",
  },
  formLabel: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectProfiles: {
    fontSize: "2rem",
    padding: "0 16px",
  },
  profiles: {
    flex: 1,
  },
  connectionErrorDialogBox: {
    padding: "40px 80px",
    textAlign: "center",
  },
  connectionErrorTitle: {
    fontSize: "1.5rem",
    color: "#707070",
    fontWeight: "bold",
  },
  connectionErrorMessage: {
    fontSize: "1.2rem",
    marginTop: "16px",
    color: "#848484",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  connectionErrorButton: {
    fontSize: "1.2rem",
    color: "#848484",
    marginLeft: "4px",
    textDecoration: "underline",
    "&:hover": {
      color: "#707070",
    },
  },
}));

const theme = createTheme({
  overrides: {
    ...overrides,
    // double the size of the initial select input label
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1px) scale(1.1)",
        fontWeight: "bold",
      },
      formControl: {
        transform: "translate(0, 30px) scale(2)",
      },
    },
    MuiButton: {
      root: {
        ...overrides.MuiButton.root,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: 32,
        letterSpacing: "normal",
      },
    },
  },
});

const Settings = ({
  checkDuplicateTablet,
  duplicateTablets,
  resetWasteEntry,
  fetchAllSectors,
  fetchAllCampuses,
  fetchKitchens,
  fetchProfiles,
  appSettings,
  campuses,
  setCampus,
  sectors,
  setSector,
  kitchens,
  setKitchen,
  profiles,
  setProfiles,
  setTabletId,
  setUnitSystem,
  pin,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["settings_page", "languages"]);

  if (isNil(pin)) {
    navigate("/pin");
  }

  useEffect(() => {
    // always get the campuses
    fetchAllSectors();
    fetchAllCampuses();

    // if we have a campus selected, then we should also refresh the kitchens
    const campus = get(appSettings, "campus");
    if (has(campus, "id")) {
      fetchKitchens({ campus });
    }

    // if we have a kitchen selected, also refresh the profiles
    const kitchen = get(appSettings, "kitchen");
    if (has(kitchen, "id")) {
      fetchProfiles({ kitchen });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const [submitting, setSubmitting] = useState(false);

  // online/offline messaging
  const { online } = useWatchConnection();

  const validationSchema = Yup.object().shape({
    sectorId: Yup.string().required(t("required.sector")),
    campus: Yup.object().required(t("required.campus")),
    kitchenId: Yup.string().required(t("required.kitchen")),
    profiles: Yup.string().required(t("required.profiles")),
    tabletId: Yup.number()
      .required(t("required.tabletId"))
      .min(1, t("required.tabletId"))
      .max(120, t("required.tabletId"))
      .test(
        "duplicateTablets",
        t("error.duplicateTablets"),
        () => !duplicateTablets.inUse
      ),
  });

  const handleSubmit = async () => {
    setSubmitting(true);
    resetWasteEntry();

    await precacheAllImages(appSettings);

    navigate("/app");
    setSubmitting(false);
  };

  // profile dialog
  const [open, setOpen] = useState(false);
  const handleOpenProfileDialog = () => {
    setOpen(true);
  };

  return (
    <Layout crumbs={getCrumbs("home/settings")}>
      {pin && (
        <Formik
          initialValues={{
            sectorId:
              get(appSettings, "sector.id") ||
              get(
                find(campuses, {
                  id: get(appSettings, "campus.id", "").toString(),
                }),
                "sector_id"
              ) ||
              "",
            campus: get(appSettings, "campus"),
            kitchenId: get(appSettings, "kitchen.id") || "",
            // profiles may have been deleted
            profiles:
              intersectionBy(get(appSettings, "profiles"), profiles, "id") ||
              [],
            tabletId: get(appSettings, "tabletId") || "",
            unitSystem: get(appSettings, "unitSystem") || "imperial",
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setTouched,
            isValid,
          }) => {
            return (
              <ThemeProvider theme={theme}>
                <Form noValidate className={classes.form}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    className={classes.container}
                    spacing={1}
                  >
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{t("sector")}</InputLabel>
                        <Select
                          disabled={!online}
                          name="sectorId"
                          value={values.sectorId}
                          className={classes.field}
                          error={errors.sectorId && touched.sectorId}
                          onChange={(event) => {
                            handleChange(event);
                            const sectorId = event.target.value;
                            const sector = find(sectors, { id: sectorId });
                            setSector({ sector });
                            setFieldValue("campus", null, false);
                            setFieldValue("kitchenId", "", false);
                            setFieldValue("profiles", [], false);
                            setFieldValue("tabletId", "", false);
                            setTouched({
                              campus: false,
                              kitchenId: false,
                              profiles: false,
                              tabletId: false,
                            });
                          }}
                          onBlur={handleBlur}
                        >
                          {sectors.map((sector) => (
                            <MenuItem key={sector.id} value={sector.id}>
                              {startCase(lowerCase(sector.name))}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.sectorId && touched.sectorId
                            ? errors.sectorId
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <CampusAutocomplete
                          loading={campuses.length === 0}
                          options={campuses}
                          onChange={(event, campus) => {
                            handleChange(event);
                            setCampus({ campus });
                            setFieldValue("campus", campus);
                            setFieldValue("kitchenId", "", false);
                            setFieldValue("profiles", [], false);
                            setFieldValue("tabletId", "", false);
                            setTouched({
                              kitchenId: false,
                              profiles: false,
                              tabletId: false,
                            });
                          }}
                          value={values.campus}
                          disabled={!online}
                          sector={appSettings.sector}
                        />
                        <FormHelperText>
                          {errors.campusId && touched.campusId
                            ? errors.campusId
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{t("kitchen")}</InputLabel>
                        <Select
                          disabled={
                            isEmpty(appSettings.campus) ||
                            isEmpty(kitchens) ||
                            !online
                          }
                          name="kitchenId"
                          value={values.kitchenId}
                          className={classes.field}
                          error={errors.kitchenId && touched.kitchenId}
                          onChange={(event) => {
                            handleChange(event);
                            const kitchenId = event.target.value;
                            const kitchen = find(kitchens, { id: kitchenId });
                            setKitchen({ kitchen });
                            setFieldValue("profiles", [], false);
                            setFieldValue("tabletId", "", false);
                            setTouched({
                              profiles: false,
                              tabletId: false,
                            });
                          }}
                          onBlur={handleBlur}
                        >
                          {sortBy(kitchens, (kitchen) =>
                            lowerCase(kitchen.name)
                          ).map((kitchen) => (
                            <MenuItem key={kitchen.id} value={kitchen.id}>
                              {startCase(lowerCase(kitchen.name))}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.kitchenId && touched.kitchenId
                            ? errors.kitchenId
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <Box display="flex">
                          <TextField
                            id="profiles"
                            label={t("profiles")}
                            disabled={!appSettings.kitchen.id || !online}
                            value={join(map(values.profiles, "name"), ", ")}
                            error={errors.profiles && touched.profiles}
                            autoComplete="off"
                            onClick={() => {
                              if (appSettings.kitchen.id && online)
                                handleOpenProfileDialog();
                            }}
                            InputProps={{
                              readOnly: true,
                              classes: {
                                root: classes.field,
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowDropDownIcon color="action" />
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{
                              classes: {
                                shrink: classes.shrink,
                              },
                            }}
                            className={classes.profiles}
                            helperText={
                              errors.profiles && touched.profiles
                                ? errors.profiles
                                : ""
                            }
                          />
                          <ProfileDialog
                            open={open}
                            profiles={profiles}
                            selectedProfiles={values.profiles}
                            handleClose={() => setOpen(false)}
                            setProfiles={(selectedProfiles) => {
                              setProfiles({ profiles: selectedProfiles });
                              setFieldValue("profiles", selectedProfiles);
                            }}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{t("unitSystem")}</InputLabel>
                        <Select
                          name="unitSystem"
                          value={values.unitSystem}
                          className={classes.field}
                          error={errors.unitSystem && touched.unitSystem}
                          onChange={(event) => {
                            handleChange(event);
                            setUnitSystem({ unitSystem: event.target.value });
                          }}
                          onBlur={handleBlur}
                        >
                          <MenuItem key="imperial" value="imperial">
                            {t("imperial")}
                          </MenuItem>
                          <MenuItem key="metric" value="metric">
                            {t("metric")}
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors.unitSystem && touched.unitSystem
                            ? errors.unitSystem
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{t("language")}</InputLabel>
                        <Select
                          disabled={!online}
                          name="language"
                          value={i18n.language}
                          className={classes.field}
                          error={errors.language && touched.language}
                          onChange={(event) => {
                            handleChange(event);
                            i18n.changeLanguage(event.target.value);
                            localStorage.setItem("lng", event.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          {LANGUAGES.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                              {t(`languages:${lang}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.language && touched.language
                            ? errors.language
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id="tabletId"
                          disabled={!online}
                          type="number"
                          label={t("tabletId")}
                          value={values.tabletId}
                          error={errors.tabletId && touched.tabletId}
                          autoComplete="off"
                          onChange={(event) => {
                            handleChange(event);
                            const tabletId = event.target.value;
                            setTabletId({ tabletId });
                            if (tabletId * 1 > 0) {
                              checkDuplicateTablet();
                            }
                          }}
                          onBlur={handleBlur}
                          onInput={(e) => {
                            // max is 120
                            e.target.value = e.target.value.slice(0, 3);
                          }}
                          InputProps={{
                            classes: {
                              root: classes.field,
                            },
                          }}
                          InputLabelProps={{
                            classes: {
                              shrink: classes.shrink,
                            },
                          }}
                        />
                        <FormHelperText>
                          {errors.tabletId && touched.tabletId
                            ? errors.tabletId
                            : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.row}>
                      <FormControl className={classes.formControl}>
                        <Box display="flex" flexDirection="row-reverse">
                          <Button
                            type="submit"
                            disabled={submitting || !isValid || !online}
                          >
                            {online ? t("submit") : t("online_required")}
                            {submitting && (
                              <CircularProgress
                                className={classes.spinner}
                                size={20}
                              />
                            )}
                          </Button>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Form>
              </ThemeProvider>
            );
          }}
        </Formik>
      )}
      <Dialog open={!online}>
        <Box className={classes.connectionErrorDialogBox}>
          <Typography className={classes.connectionErrorTitle}>
            {t("connectionErrorTitle")}
          </Typography>
          <Typography className={classes.connectionErrorMessage}>
            {t("connectionErrorMessage")}
          </Typography>
          <Typography className={classes.connectionErrorMessage}>
            <span>{t("connectionErrorBackHome1")}</span>
            <ButtonBase
              onClick={() => navigate("/app")}
              className={classes.connectionErrorButton}
            >
              {t("connectionErrorBackHome2")}
            </ButtonBase>
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  );
};

Settings.defaultProps = {
  campuses: [],
  kitchens: [],
  profiles: [],
  appSettings: {},
  pin: null,
};

Settings.propTypes = {
  checkDuplicateTablet: PropTypes.func.isRequired,
  duplicateTablets: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,

  resetWasteEntry: PropTypes.func.isRequired,

  fetchAllSectors: PropTypes.func.isRequired,
  fetchAllCampuses: PropTypes.func.isRequired,
  fetchKitchens: PropTypes.func.isRequired,
  fetchProfiles: PropTypes.func.isRequired,

  // all of the campuses, kitchens for that campus, and profiles for that kitchen
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  campuses: PropTypes.arrayOf(
    PropTypes.shape({
      // dev has some ids that are strings: eg. { id: "C-50698", name: "CSN-North Las Vegas Campus" }
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      sector_id: PropTypes.string.isRequired,
    })
  ),
  kitchens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),

  setSector: PropTypes.func.isRequired,
  setCampus: PropTypes.func.isRequired,
  setKitchen: PropTypes.func.isRequired,
  setProfiles: PropTypes.func.isRequired,
  setUnitSystem: PropTypes.func.isRequired,
  setTabletId: PropTypes.func.isRequired,

  // the chosen campus, kitchen and profiles
  appSettings: PropTypes.shape({
    campus: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
    kitchen: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    tabletId: PropTypes.string,
  }),

  // security
  pin: PropTypes.number,
};

export default Settings;
