import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import getCrumbs from '~/Layout/crumbs';
import { Layout } from '~/Layout';
import privacy from './privacy.en.html';
import { Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  privacyLink: {
    marginBottom: 20,
    paddingLeft: 20,
  }
}));

const Privacy = () => {
  const classes = useStyles();

  // OneTrust Privacy Notice
  React.useEffect(() => {
      // To ensure external settings are loaded, use the Initialized promise:
      window.OneTrust.NoticeApi.Initialized.then(function () {
        window.OneTrust.NoticeApi.LoadNotices([
          "https://privacyportal-eu-cdn.onetrust.com/8394ad8c-2b46-4837-8771-cbc69779a644/privacy-notices/f32e71bb-5951-4fb2-9d31-522d2260de35.json",
        ]);
      });
  }, [])

  return (
    <Layout crumbs={getCrumbs('home/privacy')}>
      <Box className={classes.container}>
          <Box
          id="otnotice-f32e71bb-5951-4fb2-9d31-522d2260de35"
          className="otnotice"
        ></Box>
        <Divider className={classes.divider} />
        <Typography className={classes.privacyLink}> <a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/8394ad8c-2b46-4837-8771-cbc69779a644/31bea1f4-92c3-440b-be0e-468af4f4b1f3.html?Source=WasteNote" target="_blank" rel="noreferrer">Privacy Web Form</a></Typography>
      </Box>
    </Layout>
  );
};

export default Privacy;
