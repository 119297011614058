export default {
  primary: {
    light: '#4f5b62',
    main: '#263238',
    dark: '#000a12',
    contrastText: '#fff',
  },
  secondary: {
    light: '#fdc7c7',
    main: '#fe0000',
    dark: '#870000',
    contrastText: '#fff',
  },
  background: {
    default: '#fff',
  },
};
